
import { Options, Vue } from 'vue-class-component';
import { CommunityClient, EventClient } from '@/services/Services';
import CreateEvent from './modals/createEvent.vue';
import EventCard from './components/eventCard.vue';
import * as OM from '@/model';
import { Watch } from 'vue-property-decorator';

@Options({
    components: {
        EventCard
    }
})

export default class Events extends Vue {

    communityIdentifier: string = "";

    events: OM.EventVm[] = [];

    @Watch("$route.params.slug")
    async created() {
        var community = await CommunityClient.getBySlug(this.$route.params.slug.toString())
        this.communityIdentifier = community.identifier;
        EventClient.getByCommunity(this.communityIdentifier)
        .then(x => {
            this.events = x;
        })
    }

    newEvent() {
        this.$opModal.show(CreateEvent, {
            communityIdentifier: this.communityIdentifier
        });
    }

    openEvent(item: OM.EventVm) {
        this.$router.push("/events/" + item.slug);
    }

}
